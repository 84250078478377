<template>
  <home-rtl />
</template>

<script>
import HomeRtl from "../components/HomeRtl/index.vue";
export default {
  components: { HomeRtl },
};
</script>

<style>
</style>