<template>
      <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/1.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Build A Full Web Chat App From Scratch.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/2.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Insights on How to Improve Your Teaching.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/3.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Brush Strokes Energize Trees In Paintings</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/4.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Learning Python for Data Analysis.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/5.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Logotype Masterclass with Jessica Hische</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/6.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Build A Full Web Chat App From Scratch.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/7.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Learning Python for Data Analysis.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="post-item-1">
                                    <img src="@/assets/images/blog/8.jpg" alt="">
                                    <div class="b-post-details">
                                        <div class="bp-meta">
                                            <a href="#"><i class="fal fa-clock"></i>April 22, 2020</a>
                                            <a href="#"><i class="fal fa-comments"></i>6 Comments</a>
                                        </div>
                                        <h3><a href="single-post.html">Learning Python for Data Analysis.</a></h3>
                                        <a class="read-more" href="single-post.html">Read More<i class="fal fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="bisylms-pagination">
                                            <span class="current">01</span>
                                            <a href="#">02</a>
                                            <a class="next" href="#">next<i class="fal fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>