<template>
  
      <!--====== APPIE DOWNLOAD 3 PART START ======-->
    
    <section class="appie-download-3-area pt-100" id="download">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Download app today!</h3>
                        <p>Download app for Andraoid today — it's free.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="content">
                            <h4 class="title">Android</h4>
                            <p>Download app for Android today — it's free.</p>
                            <a class="main-btn" href="#"><i class="fab fa-google-play"></i>Download for Android</a>
                        </div>
                        <div class="thumb text-center">
                            <img src="@/assets/images/download-thumb-1.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-download-3-box mt-30 ml-20 wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="content">
                            <h4 class="title">iOS & iPadOS</h4>
                            <p>Download app for iOS today — it's free.</p>
                            <a class="main-btn main-btn-2" href="#"><i class="fab fa-apple"></i>Download for iOS</a>
                        </div>
                        <div class="thumb text-right">
                            <img src="@/assets/images/download-thumb-2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE DOWNLOAD 3 PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>