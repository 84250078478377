<template>
    <home-four/>
</template>

<script>
import HomeFour from '../components/HomeFour/index'
export default {
	components: {HomeFour  },

}
</script>

<style>

</style>