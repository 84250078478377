<template>
    <home-six/>
</template>

<script>
import HomeSix from '../components/HomeSix/index'
export default {
	components: {HomeSix  },

}
</script>

<style>

</style>