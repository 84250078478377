<template>
    <News/>
</template>

<script>
import News from '../components/News/index'
export default {
	components: {News  },

}
</script>

<style>

</style>