<template>
      <!--====== APPIE PROJECT 3 PART START ======-->
    
    <section class="appie-project-3-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-project-3-box d-block d-md-flex justify-content-between align-items-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <h4 class="title">Start your project <br> with Appie.</h4>
                        <a class="main-btn" href="#">Let’s Talk</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE PROJECT 3 PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>