<template>
    <home-five/>
</template>

<script>
import HomeFive from '../components/HomeFive/index'
export default {
	components: {HomeFive  },

}
</script>

<style>

</style>