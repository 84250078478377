<template>
  
    <section class="appie-about-8-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title mb-30">
                        <h3 class="appie-title">We bring everything <br> that's required to build apps</h3>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="appie-about-8-box">
                        <h3 class="title">Will my Template be <br> Mobile Friendly</h3>
                        <p>A load of old tosh spiffing pear shaped show <br> off pick your nose and blow</p>
                        <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                        <div class="thumb">
                            <img src="@/assets/images/about-thumb-4.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="appie-about-8-box">
                        <h3 class="title">Website & Mobile <br> App Design</h3>
                        <p>A load of old tosh spiffing pear shaped show <br> off pick your nose and blow</p>
                        <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                        <div class="thumb mr-30">
                            <img src="@/assets/images/about-thumb-5.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>