<template>
  <div class="single-shop-box">
    <div class="thumb text-center">
      <img src="@/assets/images/shop-grid-1.jpg" alt="" />
      <div class="reborn">
        <span>Sale</span>
      </div>
      <div class="cart-list-icon">
        <ul>
          <li>
            <a href="#"><i class="fal fa-shopping-bag"></i></a>
          </li>
          <li>
            <a href="#"><i class="fal fa-heart"></i></a>
          </li>
          <li>
            <a href="#"><i class="fal fa-eye"></i></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="content">
      <ul>
        <li><i class="fas fa-star"></i></li>
        <li><i class="fas fa-star"></i></li>
        <li><i class="fas fa-star"></i></li>
        <li><i class="fas fa-star"></i></li>
        <li><i class="fas fa-star-half-alt"></i></li>
      </ul>
      <a href="#">Smartwatch with Music</a>
      <div class="pricing">
        <div class="discount-price">$158.00</div>
        <div class="regular-price">$180.00</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>