var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-hero-area-2 mb-90"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"appie-hero-content-2"},[_c('span',[_vm._v(_vm._s(_vm.offer_title))]),_c('h1',{staticClass:"appie-title"},[_vm._v(_vm._s(_vm.heading)+" "),_c('span',[_vm._v(_vm._s(_vm.more_heading))])]),_c('p',[_vm._v(_vm._s(_vm.description))]),_vm._m(0),_vm._m(1)])])])]),_c('div',{staticClass:"appie-hero-thumb-3 wow animated fadeInRight",attrs:{"data-wow-duration":"2000ms","data-wow-delay":"400ms"}},[_c('img',{attrs:{"src":_vm.hero_img,"alt":""}})]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"}},[_c('div',{staticClass:"input-box"},[_c('input',{attrs:{"type":"text","placeholder":"info@appie.com"}}),_c('i',{staticClass:"fal fa-envelope"}),_c('button',[_c('i',{staticClass:"fal fa-arrow-right"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-users"},[_c('img',{attrs:{"src":require("@/assets/images/hero-mans.png"),"alt":""}}),_c('span',[_vm._v("30k "),_c('span',[_vm._v(" Feedback")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-shape-1"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-9.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-shape-2"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-10.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-shape-3"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-shape-4"},[_c('img',{attrs:{"src":require("@/assets/images/shape/shape-12.png"),"alt":""}})])
}]

export { render, staticRenderFns }