<template>
      <section class="appie-blog-3-area appie-blog-8-area pt-90 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h3 class="appie-title">Latest blog posts</h3>
                        <p>The app provides design and digital marketing.</p>
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="appie-blog-item-3 appie-blog-item-8 mt-30">
                        <div class="thumb"> 
                            <img src="@/assets/images/blog-4.jpg" alt="">
                        </div>
                        <div class="content">
                            <h5 class="title"><a href="#">How to Improve Your App Store Position</a></h5>
                            <div class="meta-item">
                                <ul>
                                    <li><i class="fal fa-clock"></i> July 14, 2022</li>
                                    <li><i class="fal fa-comments"></i> July 14, 2022</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-blog-item-3 appie-blog-item-8 mt-30">
                        <div class="thumb">
                            <img src="@/assets/images/blog-5.jpg" alt="">
                        </div>
                        <div class="content">
                            <h5 class="title"><a href="#">Introducing New App Design for our iOS App</a></h5>
                            <div class="meta-item">
                                <ul>
                                    <li><i class="fal fa-clock"></i> July 14, 2022</li>
                                    <li><i class="fal fa-comments"></i> July 14, 2022</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-blog-item-3 appie-blog-item-8 mt-30">
                        <div class="thumb">
                            <img src="@/assets/images/blog-6.jpg" alt="">
                        </div>
                        <div class="content">
                            <h5 class="title"><a href="#">Engineering job is Becoming More interesting.</a></h5>
                            <div class="meta-item">
                                <ul>
                                    <li><i class="fal fa-clock"></i> July 14, 2022</li>
                                    <li><i class="fal fa-comments"></i> July 14, 2022</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-blog-item-3 appie-blog-item-8 mt-30">
                        <div class="thumb">
                            <img src="@/assets/images/blog-7.jpg" alt="">
                        </div>
                        <div class="content">
                            <h5 class="title"><a href="#">20 Myths About Mobile Applications</a></h5>
                            <div class="meta-item">
                                <ul>
                                    <li><i class="fal fa-clock"></i> July 14, 2022</li>
                                    <li><i class="fal fa-comments"></i> July 14, 2022</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="blog-btn text-center mt-60">
                        <a class="main-btn" href="#">View All Posts <i class="fal fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="appie-blog-8-shape-1">
            <img src="@/assets/images/shape/5.png" alt="">
        </div>
        <div class="appie-blog-8-shape-2">
            <img src="@/assets/images/shape/shape-12.png" alt="">
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>