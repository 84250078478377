<template>
  <shop-details />
</template>

<script>
import ShopDetails from "../components/Shops/ShopDetails.vue";
export default {
  components: { ShopDetails },
};
</script>

<style>
</style>