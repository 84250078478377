var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-faq-area appie-faq-area-rtl pb-95"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"appie-section-title text-center"},[_c('h3',{staticClass:"appie-title"},[_vm._v(_vm._s(_vm.title))]),_c('p',[_vm._v(_vm._s(_vm.description))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"faq-accordion wow fadeInRight mt-30",attrs:{"data-wow-duration":"1500ms"}},[_c('div',{staticClass:"accrodion-grp animated fadeIn faq-accrodion wow",attrs:{"data-wow-duration":"1500ms","data-grp-name":"faq-accrodion"}},[_c('div',{staticClass:"accrodion",class:[_vm.showQuestion === 1 ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(1)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(0),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion === 1
                      ? { display: 'block' }
                      : { display: 'none' },
                  ])},[_vm._m(1)])])]),_c('div',{staticClass:"accrodion",class:[_vm.showQuestion === 2 ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(2)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(2),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion === 2
                      ? { display: 'block' }
                      : { display: 'none' },
                  ])},[_vm._m(3)])])])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"faq-accordion wow fadeInRight mt-30",attrs:{"data-wow-duration":"1500ms"}},[_c('div',{staticClass:"accrodion-grp animated fadeIn faq-accrodion wow",attrs:{"data-wow-duration":"1500ms","data-grp-name":"faq-accrodion"}},[_c('div',{staticClass:"accrodion",class:[_vm.showQuestion === 3 ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(3)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(4),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion === 3
                      ? { display: 'block' }
                      : { display: 'none' },
                  ])},[_vm._m(5)])])]),_c('div',{staticClass:"accrodion",class:[_vm.showQuestion === 4 ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(4)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(6),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion === 4
                      ? { display: 'block' }
                      : { display: 'none' },
                  ])},[_vm._m(7)])])])])])]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v("أين أجد عادة الأسئلة الشائعة في الصفحة؟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح اليرقة السخيفة التي أتعامل معها. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v("أين أجد عادة الأسئلة الشائعة في الصفحة؟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح اليرقة السخيفة التي أتعامل معها. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v("أين أجد عادة الأسئلة الشائعة في الصفحة؟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح اليرقة السخيفة التي أتعامل معها. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v("أين أجد عادة الأسئلة الشائعة في الصفحة؟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" متشرد ناف أكسفورد المتشرد في بلدي الذقن المسطح اليرقة السخيفة التي أتعامل معها. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"faq-text text-center pt-40"},[_c('p',[_vm._v(" لا يمكنك العثور على إجابة؟ "),_c('a',{attrs:{"href":"mailto:demo@gmail.com"}},[_vm._v("ارسل لنا عبر البريد الإلكتروني")])])])])
}]

export { render, staticRenderFns }