<template>
  <Shops />
</template>

<script>
import Shops from "../components/Shops/index";
export default {
  components: { Shops },
};
</script>

<style>
</style>