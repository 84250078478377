<template>
      <!--====== APPIE HERO PART START ======-->
    
    <section class="appie-hero-area appie-hero-6-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="appie-hero-thumb-6">
                        <div class="thumb wow animated fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms">
                            <img src="@/assets/images/hero-thumb-7.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-hero-content appie-hero-content-6">
                        <span>Welcome To Creative App.</span>
                        <h1 class="appie-title">Get started with Appie Template.</h1>
                        <p>Lost the plot so I said nancy boy I don't want no agro bleeder bum bag easy peasy cheesed off cheers ruddy.</p>
                        <ul>
                            <li><a href="#"><i class="fab fa-apple"></i> <span>Available on the <span>App Store</span></span></a></li>
                            <li><a class="item-2" href="#"><i class="fab fa-google-play"></i> <span>Available on the <span>Google Play</span></span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE HERO PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>