<template>
      <!--====== APPIE ABOUT 3 PART START ======-->
    
    <section class="appie-about-3-area pt-100 pb-100" id="features">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 wow animated fadeInLeft" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-2.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Appie lets you launch your app in minutes.</h3>
                        <p>He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/5.svg" alt="">
                                </div>
                                <h5 class="title">Carefully designed</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30">
                                <div class="icon">
                                    <img src="@/assets/images/icon/1.png" alt="">
                                </div>
                                <h5 class="title">Seamless Sync</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="traffic-btn mt-50">
                                <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center mt-100  flex-column-reverse flex-lg-row">
                <div class="col-lg-6">
                    <div class="appie-traffic-title">
                        <h3 class="title">Browse over 40k Apps over the world</h3>
                        <p>He nicked it tickety boo harry the cras bargy chap mush spiffing spend a penny the full monty burke butty.</p>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="appie-traffic-service mb-30 item-3">
                                <div class="icon">
                                    <img src="@/assets/images/icon/6.svg" alt="">
                                </div>
                                <h5 class="title">User Interactive</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="appie-traffic-service item-2 mb-30 item-4">
                                <div class="icon">
                                    <img src="@/assets/images/icon/7.svg" alt="">
                                </div>
                                <h5 class="title">Choose a App</h5>
                                <p>Mucker plastered bugger all mate morish are.</p>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="traffic-btn mt-50">
                                <a class="main-btn" href="#">Learn More <i class="fal fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-about-thumb-3 text-right wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <img src="@/assets/images/about-thumb-3.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE ABOUT 3 PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>